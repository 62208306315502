.chartBgClr{background: #E0EBF9;    ;    padding: 30px 0px; padding-top: 70px;
    border-radius: 10px;}
.TwoCard{background: #E0F9E0; text-align: right; height: 46%; padding: 20px 20px; border-radius: 10px; display: flex; justify-content: space-between;}

.TowCard-icon {height: 40px; width: 40px; background: #fff; border-radius: 10px; display: flex; justify-content: center; align-items: center;}
.ordAndNum{ bottom: 0px; font-family: 'Inter', sans-serif;}
.toOrCus .TwoCard:last-child{margin-top: 24px; background-color: #E6E0F9;}
.ordAndNum h4{font-size: 35px; font-weight: 600; margin-bottom: 0px;}
.ordAndNum p{font-size: 18px; font-weight: 600; }
.dashTowDifColr .col-3:nth-child(3) .TwoCard{background-color: #E6E0F9;}
.recntorder-outer{padding: 20px;
    background: #FEEBEB;
    border-radius: 10px;}
.recntorder-outer th{color: #930101;}
.latestRFQ-outer{background:#F8FFCD;padding: 20px;
    border-radius: 10px;}
    .latestRFQ-outer th{color:#969001}
.latestRFQ-outer button{padding: 0px; text-decoration: underline; color: #006ff2;}
td:nth-child(1), th:nth-child(1) {
    text-align: center;
}
.UserTreeActn img, .UserTreeActn span{cursor: pointer;;}
th, td {
    text-align: center;
}

@media (max-width:767px){
/* .TwoCard{background: #E0F9E0; text-align: right; height: 40px; padding: 20px 20px; border-radius: 10px; display: flex; justify-content: space-between;} */

    .TwoCard{margin-top: 18px;}
    .TwoCard{height:110px;}
}

.DetailsView {
    padding: 0px !important;
    text-decoration: underline;
    color: #006ff2;
}
/* .recharts-layer{font-size: 11px;} */