.welcome_page{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100vw;
}

.welcome_page .box{
   width: 400px;
   height: fit-content;
   padding: 20px;
   background-color: #fff;
   box-shadow: 0 0 0 10px -4px #000;
}