.accordion-item {
  border: none;
}
.accordion-item > h2 > button {
  font-size: 24px;
  font-weight: 400;
}
.accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #000;
}
.accordion-button:focus {
  box-shadow: none;
  border-color: none;
}
.accordion-body {
  padding: 30px 0px;
}

.PackagingCir,
.ShippedCir,
.InTransitCir,
.DeliveredCir {
  height: 50px;
  width: 60px;
  background: #006ff2;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PackagingCir img,
.ShippedCir img,
.InTransitCir img,
.DeliveredCir img {
  height: 20px;
}
.Line1,
.Line2,
.Line3 {
  width: 30%;
  height: 2px;
  background: #bebebe;
}
.statusGreen {
  background: #0e9e01;
}
.statusBlue {
  background: #006ff2;
}
.trns6567 p {
  margin-bottom: 0px;
}
.trns6567 p:last-child {
  color: #bebebe;
  font-size: 13px;
  text-align: center;
}
.UpdattableStatus {
  width: 50%;
}
.UpdattableStatus td {
  /* text-align: left; */
  padding: 0px;
  color: #646464;
}
