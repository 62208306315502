


@media (max-width:767px) {
    .mobileNav{
        display: block;
    }
    .mainNavbar span {
        font-size: 15px;
    }

    .menuItems li img {
        width: 20px;
        height: 20px;
    }

    .menuItems li {
        width: 100%;
        margin-bottom: 10px;
    }

    table.table {
        width: 650px;
    }

    .latestRFQ-outer table.table{
        width: 250px;
    }

    .logo img {
        width: 150px;
    }

    .mainNavbar {
        max-width: 100%;
        width: 250px;
    }

    .userProfile-popup {
        top: 120px;
    }

    .notification-popup {
        top: 120px;
    }

    .recntorder-outer {
        margin-bottom: 10px;
    }

    .UserTreeActn {
        width: 85px;
        align-items: center;
    }

    .UserTreeActn img {
        width: 20px;
        height: 20px;
    }

    .UserNameF {
        height: 30px;
        width: 30px;
        font-size: 17px;
        line-height: 20px;
        font-weight: bold;
    }

    /*order css */
    .modal-body.orderPop {
        padding: 0px !important;
    }
    .albtnsInTabs button.btn{
        padding: 0px;
    }
    .albtnsInTabs button.btn-primary{
        padding: 5px 8px;
        font-size: 14px;
    }
    /*payment css */
    .chartText .ms-4 {
        margin-left: auto !important;
    }

    .payBtn {
        display: flex;
        gap: 5px;
    }

    .payBtn button {
        padding: 7px;
        font-size: 13px;
    }

    .chartText p {
        font-size: 18px !important;
    }

    .chartText h4 {
        font-size: 15px !important;
    }

    .PaymentChart {
        padding-top: 110px;
        padding-left: 10px;
        padding-right: 10px;
    }

    /* createe category*/
    .tab-content .offset-4 {
        margin: 0px;
    }

    .catBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 138px;
    }

    .catBtn button {
        padding: 10px 0;
        font-size: 13px;
    }
    .d-flex.justify-content-between.align-items-center.userProf {
        display: none !important;
    }
    .formobile {
        display: block;
        text-align: center;
        color: #006FF2;
    }
    .filter-mob{
        padding-top: 20px;
        display: block;
    }
}

