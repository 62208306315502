@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background: #042d5f !important;
  /* font-family: 'Roboto', sans-serif !important; */
  font-family: "Inter", sans-serif !important;
}
.btn-primary {
  background-color: #042d5f !important;
  color: #fff !important;
  border-color: #042d5f !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif !important;
}

.mainNavbar {
  font-family: "Inter", sans-serif;
}

.theme-color {
  color: #006ff2 !important;
}

.orderAccordion .accordion-button {
  padding-top: 0px;
}

.orderAccordion .accordion-body {
  padding-bottom: 0px;
}

.orderAccordion .accordion-button {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.recharts-tooltip-wrapper p {
  margin: 0px;
}

.recharts-tooltip-wrapper p {
  display: none;
}

.react-calendar__navigation button,
.react-calendar__navigation {
  min-width: 23px !important;
}

.theme-btn {
  background: #042d5f !important;
  color: #fff !important;
  border: 0px;
}

.theme-btn-green {
  background-color: #0ac806 !important;
  color: #fff !important;
}

.theme-bg {
  background: #042d5f !important;
}

.theme-bg-color-gray {
  background-color: #f4f4f4 !important;
}

.color-gray {
  color: #a4a0a0;
}

.main-Conatiner {
  box-sizing: border-box;
  padding: 15px;
}

.mainNavbar {
  color: #fff;
  position: fixed;
  top: 0px;
  background: #042d5f !important;
  height: 100vh;
  padding-top: 20px;
  max-width: 330px;
  width: 100%;
}

.logotext {
  font-size: 34px;
  font-weight: 400;
  margin-top: 10px;
}

.logo img {
  margin-right: 9px;
  width: 200px;
  margin-left: 20px;
}

.logotext span {
  color: #006ff2;
}

.menuItems {
  margin-top: 2rem;
  overflow-y: scroll;
  max-height: 500px;
  padding-left: 20px;
}

.UserEdit_outer {
  width: 240px;
  /* background: red; */
  box-sizing: border-box;
  padding: 15px;
  padding-left: 0px;
  padding-right: 30px;
}

.UserEdit_pic {
  width: 100%;
  height: 200px;
  background: lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
}

.UserEdit_pic p:first-child(1) {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
}

.addAccountBtn-Outer {
  height: 209px;
  width: 300px;
  right: 2%;
  bottom: 15px;
}

.addSubAccount_popUp {
  position: relative;

  width: 203px;

  border-radius: 10px;
  margin-left: 66px;
}

.addAccountBtn-Outer > div {
  height: 100%;
}

.addAccountBtn-Outer {
  height: 209px;
  width: 300px;
  right: 2%;
  bottom: 15px;
}

.addSubAccount_popUp {
  position: relative;
  width: 203px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  margin-left: 66px;
  display: none;
}

.addSubAccount_popUp p {
  font-size: 14px;
  margin-bottom: 0px;
}

.addUseIcon:hover .addSubAccount_popUp {
  display: block;
}

.addAccountBtn-Outer img {
  position: absolute;
  right: 0px;
  bottom: 0;
  cursor: pointer;
}

.menuItems::-webkit-scrollbar {
  width: 2em;
  height: 2em;
}

.modal-body {
  padding: 30px !important;
}

.accordion-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.menuItems li {
  width: 100%;
  /* margin-bottom: 19px; */
  margin-bottom: 6px;
}

.vinn {
  position: fixed;
  bottom: 10px;
  left: 0;
  background: #042d5f;
  width: 25%;
  height: 40px;
  line-height: 40px;
  /* text-align: center; */
  /* border-radius: 10px; */
  margin-top: 15px;
}

.vinn > div {
  margin-left: 20px;
  justify-content: space-between;
  padding-right: 20px;
}

.recentTrans {
  height: 300px;
}

.productTBlDt .table td {
  text-align: left !important;
  padding-left: 0px;
}

.productTBlDt98 {
  border: 1px solid #c6c6c6;
  border-radius: 6px;
  margin-bottom: 13px;
}

.productTBlDt98 {
  margin-left: 12px;
}

.productTBlDt98 .subPro8780 {
  margin-top: 10px;
}

.menuItems li a {
  width: 100%;
  display: block;
  text-decoration: none;
  padding: 8px 0px;
  color: #fff;
  /* font-size: 17px; */
  font-size: 15px;
  font-weight: 400;
  padding-left: 8px;
}

/* .rowGray td:nth-last-child(1) {
  float: left;
} */

.milestoneTable td,
.milestoneTable th {
  width: 25%;
}

.milestoneTable tr {
  display: flex !important;
}

.milestoneTable tr button {
  float: right;
}

.menuItems img {
  margin-right: 10px;
}

.rightSideOutContainer {
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 45px 60px;
  height: 100%;
}

.UserTreeActn {
  width: 108px;
  display: flex;
  justify-content: flex-end;
}

.NotiPro_Outer {
  display: none;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  height: 100em;
  position: absolute;
  left: 0;
  top: 0;
}

.UserNameF {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: #042d5f;
  font-size: 20px;
  color: #fff;
}

.userName p {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.userName h3 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
}

.modal-content {
  background-color: #fff !important;
}

.loanSaleChart87 {
  background-color: #e6e6e6;
  border-radius: 10px;
  padding: 15px 0;
  min-height: 300px;
  padding-bottom: 0px;
}

.loanSaleChart87 .recharts-surface {
  padding-left: 0;
}

.PaymentChart {
  background: #e0ebf9;
  border-radius: 10px;
  padding: 15px;
  padding-top: 81px;
  padding-bottom: 20px;
}

table.rowGray {
  border-collapse: separate;
  border-spacing: 0 1em;
  font-family: "Roboto", sans-serif !important;
}

table.rowGray tr {
  background-color: #ebebeb;
}

table tr th {
  font-family: "Roboto", sans-serif !important;
}

table tr td {
  font-family: "Inter", sans-serif !important;
}

table.rowGray thead tr:nth-child(1) {
  background-color: #fff;
  border-bottom: 0px solid #fff;
}

.support-detail-container .form-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 15px;
}
button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

table caption {
  caption-side: top;
}
.support-detail-container .form-group label {
  font-weight: 500;
}

.userProfile-points img {
  width: 30px;
  margin-right: 10px;
}

.userProfile-points p {
  color: #7e7e7e;
}

.userProfile-popup {
  background-color: #fff;
  border-radius: 10px;
  height: auto;
  width: 200px;
  position: absolute;
  right: 30px;
  top: 112px;
  z-index: 1;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: none;
}

.notification-popup {
  background-color: #fff;
  border-radius: 10px;
  height: auto;
  width: 300px;
  position: absolute;
  right: 30px;
  top: 112px;
  z-index: 1;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: none;
}

.cerTxt {
  height: 45px;
  width: 45px;
  background: #006ff2;
  color: #fff;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pro25pers {
  width: 30%;
  background-color: #006ff2;
  height: 5px;
}

.pro50pers {
  width: 33%;
  background-color: #006ff2;
  height: 5px;
}

.trLine {
  margin-top: 15px;
}

.trLine p {
  margin-bottom: 0px;
}

.trLine p:nth-child(2) {
  color: #adadad;
  font-size: 13px;
}

.trLine p.compl {
  color: #03a927;
}

.trLine p.Notify {
  color: #930101;
}

.zInx {
  z-index: 1;
}

.trLine1 {
  width: 80% !important;
  left: 10%;
  height: 5px;
  background: #d4d4d4;
  z-index: 0;
  top: 20px;
}

.walletouter {
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  margin-top: 20px;
}

.walletouter p {
  text-align: left;
  color: #006ff2;
  font-size: 13px;
  margin-bottom: 10px;
}

.walletouter h3 {
  text-align: left;
  font-size: 19px;
}

.walletimg {
  height: 50px;
  background: #d4dae1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 5px;
}

.recharts-wrapper {
  width: 100% !important;
}

.recentTrans {
  /* background: #e8e5e5; */
  padding: 20px;
  border-radius: 10px;
}

.recentTrans tr:first-child {
  background: #e8e5e5;
}

.recentTrans tr td{
  text-align: justify;
}

.shipDtl p {
  margin-bottom: 6px;
}

.shipDtl p:last-child {
  margin-bottom: 0px;
}

.shipDtl p span {
  color: #006ff2;
  font-weight: 600;
}

.recentTrans h3 {
  font-size: 22px;
}

.shipDtl_outer > p {
  color: #6c6c6c;
  font-size: 22px;
}

.recentTrans td {
  padding: 5px 20px !important;
}

.addresTable p {
  margin-bottom: 0px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.addresTable p:nth-child(2) {
  color: #706f6f;
}

.calendTabs .tablCusNav {
  width: 40% !important;
  padding: 15px;
}

.react-calendar {
  border: 0px solid !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.calendTabs .allBtnShowtab {
  width: 60% !important;
}

.modal-content-transparent {
  background: transparent !important;
  border: 0px !important;
}

.tablCusNav {
  padding: 15px;
}

.subTTLTBL {
  font-weight: bold;
}

.subTTLTBL tr td:nth-child(2) {
  text-align: right;
}

.subTTLTBL tr td:nth-child(1) {
  text-align: left;
}

.advncPaymTable tr th {
  color: #838282;
}

.tablCusNav {
  padding: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}

.allBtnShowtab {
  background: #fff;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
}

.TabsBTNSC {
  margin-bottom: 10px;
}

.btn-check:checked + .btn {
  background-color: #006ff2 !important;
  color: #fff !important;
}

.RFQ_R_Details h4 {
  color: #a1a1a1;
  margin-bottom: 0px;
}

.Requested-Items1 p,
.Requested-Items2 p {
  margin-bottom: 0px;
}

.faqTableleft th,
.faqTableleft td {
  text-align: left !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.border-rightside {
  border-right: 1px solid #9a9a9a;
}

.SubMenuItems {
  display: none;
}

#Catalouge {
  display: none;
  background-color: #a2d2ff;
  border-radius: 10px;
  margin-left: 0px;
  margin-top: 6px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

.menuItems #Catalouge a {
  text-decoration: none;
  color: #000 !important;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.menuItems .active {
  background: #fff;
  color: #006ff2;
  border-radius: 10px;
  padding-left: 8px;
}

.menuItems .active img.icon1to2-s {
  display: inline-block;
}

.menuItems .active img.icon1to2-f {
  display: none;
}

.menuItems img.icon1to2-s {
  display: none;
}

.menuItems img.icon1to2-f {
  display: inline-block;
}

#toogleIcon {
  display: none;
}

#closeIconMenu {
  display: none;
}

.limebTnTwo202 a {
  width: 25%;
}

#SideMenuHS {
  height: 100vh;
}

.logstreebutt {
  width: 150px;
}

.form-control:focus {
  background-color: #e6e6e6 !important;
  box-shadow: none !important;
}

.topSerrch21 {
  background: #e6e6e6;
  border-radius: 8px;
}

.Notify {
  cursor: pointer;
}

.topSerrch21 input {
  background: #f4f4f4;
  border: none;
}

.cate-8822-heading {
  margin-top: 13px;
}

.topSerrch21 span {
  background: #f4f4f4;
  cursor: pointer;
  border: 0px;
}

.milestoneTable tr {
  background-color: #fff !important;
}

.chosSec {
  min-height: 190px;
  box-sizing: border-box;
  padding: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed;
  border-radius: 10px;
}

.rowGray th:nth-last-child(1) {
  /* padding-right: 0px; */
  /* float: right; */
  /* padding-bottom: 0px; */
  /* padding-top: 0px; */
}

.rowGray td:nth-last-child(1) {
  padding-right: 0px;
  /* float: right; */
  padding-bottom: 0px;
  padding-top: 0px;
}

.rowGray th .btn,
.rowGray td .btn {
  padding: 12px;
}

.milestoneTable tr {
  margin-bottom: 10px;
  display: table;
  width: 100%;
}

.rowGray th {
  padding-top: 0px;
  padding-bottom: 0px;
  letter-spacing: 1px;
  font-weight: 600;
}

.nogetiationDetailsTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.flex-box button {
  background: #042D5F;
  color: #fff;
  margin-top: 20px;
  padding: 10px 30px;
  width: 200px;
  border-radius: 8px;
  height: 40px;
  border: #042D5F;
}

.NogeModal .milestoneTable tr {
  margin-bottom: 0px;
}

.NogeModal .milestoneTable .table {
  width: 90%;
  margin: 0px auto;
}

.NogeModal .milestoneTable tr {
  justify-content: space-between;
}

.addprhng {
  font-size: 23px;
}

.ShowEntriesBox {
  background-color: #fff !important;
}

#AdvanceInformation .accordion-item .accordion-button,
.BasicInformationAllBh .accordion-item .accordion-button {
  font-size: 20px;
}

.accordion-item .top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 50px;
}
.accordion-item .top p {
  color: #000;
  font-size: 15px;
  display: flex;
  gap: 30px;
}

.accordion-item {
  border: 1px solid #d9d9d9 !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.accordion-item .accordion-body {
  padding: 20px;
  border-radius: 10px;
}

button.accordion-button {
  background: #f8f8f9 !important;
  padding: 20px 25px !important;
  border-radius: 10px !important;
}

.support_details textarea {
  width: 100%;
  background: #f8f8f8;
  border: #f8f8f8;
  border-radius: 8px;
  height: 200px;
  padding: 10px 20px;
}
.support_details .form-group {
  font-size: 15px;
  display: flex;
  width: 100%;
}

.support_details .buttons {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.support_details button.cancel {
  background: #fff;
  border: #fff;
  color: #042d5f;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #042d5f;
  width: fit-content;
}
.support_details button.resolve {
  background: #042d5f;
  border: 1px solid #042d5f;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}
.support_details input {
  width: 100%;
  padding: 10px 20px;
  border: #f8f8f8;
  background: #f8f8f8;
  border-radius: 8px;
}

.nogetiationDetailsTable tr td:nth-child(1) {
  font-weight: 400;
}

.nogetiationDetailsTable tr td:nth-child(2) {
  font-weight: 300;
}

.nogetiationDetailsTable td {
  text-align: left !important;
}

.rowGray td {
  padding-top: 0px;
  padding-bottom: 0px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 15px;
}

.milestoneTable td:nth-child(1),
.milestoneTable th:nth-child(1) {
  text-align: left !important;
}

.subMenuCateIcon2,
.subMenuBrandIcon2 {
  display: none;
}

.SubMenuItems a.active .subMenuCateIcon2,
.SubMenuItems a.active .subMenuBrandIcon2 {
  display: inline-block;
}

.SubMenuItems a.active .subMenuCateIcon1,
.SubMenuItems a.active .subMenuBrandIcon1 {
  display: none;
}

.chosSec h4 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0px;
}

.form-switch .form-check-input {
  width: 3em !important;
  height: 1.5em;
}

.mobileNav {
  display: none;
}

.MilestonePNa p {
  font-size: 15px;
}

.userPr78 a {
  display: block;
  color: #000;

  text-decoration: none;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-top: 5px;
}

.userPr78 a:first-child {
  margin-top: 0px;
}

.userPr78 a:last-child {
  border-bottom: 0px solid lightgray;
  padding-bottom: 0px;
}

.responFaqImgMain {
  margin-top: -40px;
}

@media (max-width: 767px) {
  .responFaqImgMain {
    margin-top: 0px;
  }

  .rowGray th:nth-last-child(1) {
    float: none !important;
    padding-bottom: 6px !important;
  }

  .btnFilterhideMob {
    display: none !important;
  }

  .subTTLTBL .table {
    width: 100% !important;
  }

  .subTTLTBL .table td:nth-child(1),
  .subTTLTBL .table th:nth-child(1) {
    text-align: left !important;
  }

  #RelatedProducts,
  #UpsellProduct {
    max-width: 1280px;
    overflow: scroll;
    width: 100%;
  }

  #RelatedProducts .accordion-body,
  #UpsellProduct .accordion-body {
    min-width: 767px;
  }

  #RelatedProducts .accordion-body .table,
  #UpsellProduct .accordion-body .table {
    width: 100%;
  }

  .ImgaGalimg img {
    margin-bottom: 15px;
  }

  .accordionBodyBNS button {
    margin-top: 15px;
  }

  .vinn {
    display: none;
  }

  .main-Conatiner .showNavbar {
    display: block;
  }

  .logstreebutt {
    width: 100%;
  }

  .fWidScl {
    width: 100%;
    overflow: scroll;
  }

  .fWidScl > div {
    min-width: 767px;
  }

  .limebTnTwo202 a {
    width: 100%;
  }

  #toogleIcon {
    height: 45px;
    width: 75px;
    position: absolute;
    display: block;
    cursor: pointer;
  }

  #SideMenuHS {
    position: absolute;
    /* left: -300px; */
    left: -400px;
    height: 100vh;
  }

  .showsiemenu12 {
    left: 0px !important;
    z-index: 9;
  }

  /* #closeIconMenu {
        display: block;
        display: none;
        right: -34px;
        position: absolute;
        top: 30px;
        height: 28px;
        width: 36px;
    } */
  #closeIconMenu {
    display: block;
    display: none;
    right: 21px;
    position: absolute;
    top: 16px;
    height: 17px;
    width: 20px;
    filter: invert(60%) sepia(69%) saturate(4%) hue-rotate(26deg)
      brightness(209%) contrast(109%);
  }

  .rightSideOutContainer {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .imgCate-outer0228 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .imgCate-outer0228 img {
    width: 100%;
  }
}

.formobile {
  display: none;
}

.filter-mob {
  display: none;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.dashTowDifColrNew56 {
  box-sizing: border-box;
}

.recharts-surface {
  padding: 0 15px;
}

.PaymentChart .recharts-surface {
  padding: 0 4px;
  margin-top: 15px;
}

.recharts-cartesian-axis-ticks {
  margin-left: -20px;
}

.dashTowDifColrNew56 .recharts-surface {
  padding: 0px 0px;
  margin-top: 23px;
}

#inputID::placeholder {
  color: #000;
  opacity: 0.5;
  font-size: 14px;
}

.downArrowIcon1 {
  display: none;
}

#Catalougetoggle .downArrowIcon1,
#Catalougetoggle .downArrowIcon1 {
  display: block;
}

#Catalougetoggle .active .downArrowIcon1,
#Catalougetoggle .active .downArrowIcon1 {
  display: none;
}

#Catalougetoggle .active .downArrowIcon2,
#Catalougetoggle .active .downArrowIcon2 {
  display: block;
}

.infoTable td {
  text-align: left !important;
}

.infoTable td:nth-child(1) {
  text-align: left !important;
  font-weight: 500;
}

.infoTable td:nth-child(2) {
  text-align: left !important;
  font-weight: 400;
}

.infoTable td a {
  padding-left: 0px;
}

.btn-check4BTN:checked + .btn {
  background-color: #0ac806 !important;
}

.inputFieldOuter {
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.inputFieldOuter .addprhng {
  font-size: 21px;
  font-weight: 400;
}

.inputFieldOuter label {
  font-size: 14px;
}

.milestoneRecord td {
  font-size: 14px;
  text-align: left !important;
  border-bottom: none;
}

.milestoneRecord tr {
  background: #fff !important;
}

.milestoneRecord td:nth-last-child(1) {
  padding-right: 0px;
  float: left;
  padding-bottom: 0px;
  padding-top: 10px;
  font-weight: 400;
}

.milestoneRecord td:nth-child(1) {
  font-weight: 600;
}

.buyrDDl {
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 18px 25px;
  border-radius: 10px;
}

.buyrDDl h4 {
  font-size: 20px;
  margin-bottom: 30px;
}

.cmpnyaddTabl td {
  text-align: left !important;
  border-bottom: 0px;
}

.cmpnyaddTabl td:nth-child(1) {
  font-weight: 600;
}

.cmpnyaddTabl td:nth-child(2) {
  font-weight: 400;
}

.milestoneTableNoMarginBtm tr {
  margin-bottom: 5px !important;
}

.milestoneTableNoMarginBtm tr {
  margin-bottom: 5px !important;
}

.milestoneTableNoMarginBtm td .btn {
  padding: 8px;
}

.overLaySerchCateLouge {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
  left: 0px;
  top: 0;
  display: none;
}

.topSerrch21 {
  z-index: 99;
}

.productTBlDt98New {
  margin-bottom: 0px;
}

/* new css */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.product-details-container {
}

.pd-divider {
  display: flex;
  width: 100%;
}

.pd-divider .pd-left {
  width: 40%;
  padding: 20px;
  position: sticky;
  top: 74px;
  height: fit-content;
  left: 0;
  z-index: 99;
}

/* .pd-divider .pd-left div{
    background-color: rgb(209, 208, 208);
    width: 100%;
    height: 350px;
} */
.pd-divider .pd-right {
  width: 60%;
  padding: 20px;
}

/* .pd-divider .pd-right .pd-right-details{
     width: 100%;
} */

.h2-heading {
  font-size: 25px;
  text-align: center;
  margin: 50px 0 30px;
}

/*************************************************************************************************************/

.pd-divider .pd-right .pd-right-details {
  width: 90%;
}

.product-name {
  font-size: 35px;
  margin-bottom: 25px;
  font-weight: 500;
}

.product-rate-avail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.product-availibility {
  font-weight: 600;
  color: green;
}

.product-rating {
  /* display: flex; */
}

.rating-heading {
  font-weight: 500;
  font-size: 13px;
  padding-left: 3px;
  color: #898c89;
}

.stars-div {
  display: flex;
  align-items: center;
}

.start-b {
  font-size: 21px;
  color: #006ff2;
}

.start-g {
  font-size: 21px;
  color: #aaacac;
}

.ratings-numbers {
  font-weight: 500;
  font-size: 11px;
  padding-left: 3px;
  color: #898c89;
  padding-bottom: 5px;
}

.uni-heading {
  font-weight: 500;
  margin-bottom: 15px;
}

.product-des {
  margin-bottom: 25px;
}

.product-des p {
  font-size: 14px;
}

.product-size {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.product-size svg {
  transform: translate(-30px, 7px);
}

.product-size select {
  margin-bottom: 15px;
}

.size-dropdown {
  width: 138px;
  padding: 0px 10px;
  background: #f6f6f6;
  border-radius: 7px;
  font-weight: 600;
  border: none;
  outline: none;
  font-family: inherit;
  font-style: inherit;
  height: 40px;
}

.product-qty-pincode {
  display: flex;
  justify-content: space-between;
}

.product-qty-input {
  display: flex;
  border-radius: 7px;
  overflow: hidden;
  align-items: center;
}

.product-qty-input button {
  border: none;
  height: 40px;
  background-color: #f6f6f6;
  color: #434141 !important;
  width: 100px;
}

.product-qty-input button svg {
  font-size: 15px;
}

.product-qty-input .show-qty {
  background: #f6f6f6;
  border: none;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 65px;
  outline: none;
  font-size: 18px;
  color: #434141;
  align-items: center;
  font-weight: 600;
}

form.product-pincode-input {
  display: flex;
  height: 40px;
}

form.product-pincode-input input {
  border: none;
  background: #f6f6f6;
  border-radius: 5px 0 0 5px;
  outline: none;
  padding: 0 10px;
  font-size: 14px;
}

form.product-pincode-input button {
  border-radius: 0 5px 5px 0;
  border: none;
  background: #f6f6f6;
}

.table-c {
  width: 100%;
  overflow-x: scroll;
}

table.seller-price-table.pdt {
  /* padding-top: 10px; */
  margin-bottom: 25px;
  /* min-width: 694px; */
  min-width: 440px;
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
}

.pdt .tablerow-head {
  vertical-align: middle;
  height: 40px;
  font-size: 15px;
  color: #000;
  background: #e7f2ff;
  border-radius: 5px;
}

.table-head {
  text-align: center;
  font-weight: normal;
}

.pdt .tablerow-data {
  height: 50px;
  font-size: 15px;
  font-weight: 500;
}

.inp-td {
  width: 45px !important;
  vertical-align: top;
  height: 26px;
}

.table-data {
  width: 130px;
}

.price-heading {
  font-weight: 500;
  /* margin-bottom: 5px; */
  color: #520000;
}

.ite-price-container {
  margin: 10px 0 20px;
}

span.old-price {
  color: gray;
  text-decoration: line-through;
}

span.price {
  margin-right: 15px;
  font-size: 35px;
  font-weight: 500;
}

span.price-span {
  font-weight: 400;
  color: gray;
  font-size: 13px;
}

.discount-percentage {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #006ff2;
}

.price-btn {
  margin-right: 15px;
  height: 36px;
  width: 50%;
  border-radius: 6px;
  border: none;
  background: #006ff2;
  color: white;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 15px;
}

.pd-right-details .table-c,
.logistics-table-container {
  overflow-x: unset !important;
}

.price-desc {
  margin-bottom: 25px;
}

.logistics-table-container {
  padding-top: 5px;
  margin-bottom: 25px;
  width: 100%;
  overflow-x: scroll;
}

.logistics-table-container table.logistics-table {
  min-width: 695px;
  /* background: red; */
}

@media (max-width: 767px) {
  .logistics-table-container table.logistics-table {
    min-width: 200px;
    overflow-x: scroll;
  }

  table.seller-price-table.pdt {
    min-width: 2px;
  }

  .product-qty-pincode {
    flex-direction: column;
  }

  .product-size {
    width: 100% !important;
  }

  .RadioBTNbutton {
    clear: both;
    float: left;
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  .SelectMl {
    width: 100% !important;
  }

  .product-size .tab1 {
    width: 100% !important;
  }

  .product-qty-input {
    display: flex;
    border-radius: 7px;
    overflow: hidden;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
    background: #f6f6f6;
  }

  .product-qty {
    width: 100%;
  }
}

tr.logistics-headrow {
  height: 41px;
  background: #c6eeff;
  font-size: 12px;
}

th.logistics-head {
  border-spacing: 0;
  padding: 0 10px;
  text-align: center;
}

td.logistics-data {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  text-align: center;
}

.dd-box {
  width: 137px;
  border-radius: 6px !important;
  overflow: hidden;
}

.select-size {
  height: 37px;
  background: #f0f0f0;
  font-size: 15px !important;
  font-weight: 500 !important;
  outline: none !important;
}

@media screen and (max-width: 1000px) {
  .pd-divider {
    flex-direction: column;
  }

  .pd-divider .pd-left {
    width: 100%;
    padding: 0px;
    position: unset;
  }

  .pd-divider .pd-right {
    width: 100%;
    padding: 0px;
  }

  .pd-divider .pd-right .pd-right-details {
    width: 90%;
    padding: 15px;
  }

  .product-qty {
    margin-bottom: 25px;
  }

  /* .product-qty-pincode {
    flex-direction: column;
  } */
  .product-size {
    width: 50%;
    flex-direction: column;
    align-items: unset;
  }

  form.product-pincode-input {
    width: 100%;
  }

  form.product-pincode-input input {
    width: 100%;
  }

  .product-pincode {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .product-name {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: 500;
  }

  /* .price-btn-container {
    display: flex;
    justify-content: space-between;
  } */

  button.price-btn {
    margin-right: 0px;
    height: 36px;
    width: 49%;
  }
}

.type-selector {
  width: 110px;
  height: 31px;
  border-radius: 6px;
  padding-left: 8px;
  font-weight: 500;
  font-family: inherit;
  background: #f6f6f6;
  border: none;
  outline: none;
}

.infoic {
  position: relative;
}

.infoic:hover .infoic-box {
  display: block;
}

/* .infoic-box{position: absolute; width: 300px; background-color: #fff;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; box-sizing: border-box; padding: 15px; border-radius: 10px; display: none;height: 116px;
  overflow: scroll;} */

.infoic-box {
  position: absolute;
  width: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  /* display: block; */
  /* height: 116px; */
  /* overflow: scroll; */
  top: 35%;
  left: 30%;
}

.infoic-box h5 {
  margin: 0px;
  font-size: 12px;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
}

.infoic-box h4 {
  margin: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.infoic-box p {
  margin-top: 0px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.table-cNoScroll {
  overflow-x: unset;
}

.pdf-card {
  /* width: 25% !important; */
  /* box-shadow: 0px 4px 10px rgb(0 0 0 / 25%); */
  border-radius: 10px;
}

.pdf-card .pdf-header {
  width: 100% !important;
  box-shadow: none;
  border-radius: 10px 10px 0px 0px;
  height: 220px;
  border-bottom: 1px solid #a5a2a2;
}

.dfltCheck {
  background: #006ff2 !important;
  color: #fff;
}

.RadioBTNbutton {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.RadioBTNbutton label,
.RadioBTNbutton input {
  background-color: #efefef;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.RadioBTNbutton input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.RadioBTNbutton input[type="radio"]:checked + label {
  background: #006ff2;
  border-radius: 4px;
  color: #fff;
}

.RadioBTNbutton label {
  cursor: pointer;
  z-index: 90;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
}

.tab2,
.tab3 {
  display: none;
}

@media screen and (max-width: 600px) {
  .pd-divider .pd-right .pd-right-details {
    width: 100%;
    padding: 15px;
  }

  .RadioBTNbutton {
    width: 118px;
    height: 38px;
  }

  .product-qty-pincode {
    align-items: flex-start;
  }

  .uni-heading > span {
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 520px) {
  .infoic-box {
    position: absolute;
    width: 190px;
  }
}

.pd-sort-btn {
  height: 42px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  color: #000;
  background: unset;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
}

.sort-div input {
  display: none;
}

.sort-div label {
  height: 34px;
  display: block;
  width: 100px;
  display: inline-flex;
  align-items: center;
  background: #fdf8f8;
  color: black;
  font-size: 12px;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 5px;
}

.sort-div label:hover {
  font-weight: 500;
  background: #006ff2;
  color: white;
}

input[type="radio"]:checked + label {
  font-weight: 500;
  background: #006ff2;
  color: white;
}

.sort-div-con {
  left: 40%;
  position: relative;
}

.sort-div-con > form {
  position: absolute;
  display: none;
  padding-top: 5px;
  overflow: hidden;
}

.sort-div-con:hover form {
  display: block;
}

.sort-div {
  border: 1px solid #b9abab;
}

.sort-div:first-child {
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.sort-div:last-child {
  overflow: hidden;
  border-radius: 0 0 5px 5px;
}

@media screen and (max-width: 570px) {
  .uni-pp {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .uni-pp > span {
    width: 100% !important;
  }

  .RadioBTNbutton {
    clear: both;
    float: left;
    margin-bottom: 10px !important;
    width: 31% !important;
  }

  .tab1 {
    width: 100% !important;
    position: relative;
  }

  .tab2 {
    width: 100% !important;
    position: relative;
  }

  .tab3 {
    width: 100% !important;
    position: relative;
  }

  .tab1 > select {
    width: 100% !important;
  }

  .tab2 > select {
    width: 100% !important;
  }

  .tab3 > select {
    width: 100% !important;
  }

  .downarrow {
    width: 24px;
    display: block;
    position: absolute;
    right: 11px;
    top: 9px;
  }
}

.downarrow {
  display: none;
}

.pdt .tablerow-data .plati span {
  background: rgba(242, 242, 242, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}

.pdt .tablerow-data .gold span {
  background: rgba(255, 215, 156, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}

.pdt .tablerow-data .blue span {
  background: #044b9f;
  padding: 7px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}

table.seller-price-table.pdt a {
  color: #006ff2;
  text-decoration: underline !important;
  font-size: 15px;
}

/* add products css */
.profile_page form {
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;
  padding: 30px;
  border-radius: 10px;
}

.profile_page .form-control label {
  color: #7b7b7b;
  display: block;
  font-size: 15px;
  padding-bottom: 15px;
}

.profile_page .form-control {
  /* border: unset; */
  position: relative;
}

.profile_page .form-control input {
  padding: 7px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.profile_page .form-control svg {
  position: absolute;
  right: 5%;
  top: 62%;
  color: #9b9b9b;
  font-size: 19px;
}

.profile_page .form-control label {
  color: #000;
}

.profile_page .form-control textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.profile_page .form-select {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  color: #ccc;
}

.profile_page .form .form-check-label {
  font-size: 12px;
}

.profile_page h4 {
  padding-bottom: 10px;
  font-size: 19px;
  font-weight: 600;
}

.profile_page button.add {
  color: #fff;
  background: #042d5f;
  border-radius: 10px;
  border: #042d5f;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.profile_page button.add svg {
  font-size: 17px;
}

.profile_page span {
  font-size: 12px;
}

.profile_page svg.icon {
  position: absolute;
  right: 0%;
  top: 47%;
  background: #042d5f;
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  font-size: 20px;
  padding: 11px;
  height: 43px;
  width: 50px;
}

button.cancel {
  background: #fff;
  border: #fff;
  color: #042d5f;
  padding: 10px;
  width: 50%;
}

button.continue {
  background: #042d5f;
  border: 1px solid #042d5f;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 50%;
}

/* rfq css */
.rfq-page .inputs {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

.rfq-page .inputs label {
  width: 30%;
  display: inline-block;
  font-size: 14px;
}

.rfq-page .row {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.rfq-page .inputs span {
  color: #7b7b7b;
  font-size: 14px;
}

.rfq-page .column2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.rfq-page .column2 .inputs a {
  font-size: 15px;
  text-decoration: underline !important;
  color: #006ff2;
}

.rfq-page .column2 .inputs span {
  width: 20%;
}
.rfq-page .pdt_Detail {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}
.rfq-page .pdt_Detail h6 {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  margin: 30px 0;
}
.rfq-page .additional h6 {
  font-size: 16px;
  color: #000;
  padding: 15px 0;
}

.rfq-page .additional {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.rfq-page .additional .inputs {
  padding-bottom: 10px;
}

.rfq-page .additional .inputs label {
  color: #7b7b7b;
}
.rfq-page button.accordion-button {
  background-color: rgba(0, 0, 0, 0.03) !important;
  padding: 20px 25px !important;
}
.rfq-page .accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.rfq-page .accordion-body {
  padding: 20px;
}
.rfq-page .top {
  display: flex;
  gap: 40px;
}

.rfq-page .top p {
  color: #7b7b7b;
  font-size: 15px;
  margin: 0;
}

.rfq-page .bottom .btn-input {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.rfq-page .bottom .btn-input .accept {
  background: #006ff2;
  border: 1px solid #006ff2;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}

.rfq-page .bottom .btn-input .response {
  background: #e8e8e8;
  border: #e8e8e8;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}

.rfq-page .bottom .account {
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
}

.rfq-page .bottom .account .detail {
  display: flex;
  width: 50%;
}

.rfq-page .bottom .account .detail label {
  width: 200px;
}

.rfq-page .bottom .account .detail span {
  color: #7b7b7b;
}
.rfq-page .form-control {
  /* border: unset; */
}
.response-modal button.btn-close {
  font-size: 15px;
}
.response-modal .modal-header {
  border: unset;
}
.response-modal .modal-header .modal-title {
  font-size: 20px;
}
.response-modal .modal-body {
  padding: 0 20px 20px !important;
}
.response-modal .form-control {
  border: unset;
}
.response-modal .form-control label {
  display: block;
  font-size: 14px;
  padding-bottom: 10px;
}
.response-modal .form-control input {
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.response-modal .form-control textarea {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.response-modal .form-btn {
  display: flex;
  gap: 20px;
}
.response-modal .form-btn button.cancel {
  background: #e8e8e8;
  border: #fff;
  color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  width: 100%;
}
.response-modal .form-btn button.continue {
  background: #006ff2;
  border: 1px solid #006ff2;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
}

/* order css */

.oder-detail .delivery-status-box {
  padding: 20px 0;
  background: #fff;
  border-bottom: 1px solid #c7c7c7;
}
.oder-detail .delivery-status-box .bhd-sub-heading {
  margin-top: 0px;
}
.oder-detail .bhdBorder {
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.oder-detail .bhdBorder .bhd-sub-heading {
  margin-top: 0px;
}
.oder-detail .bhdBorder .bhd-table th {
  font-weight: 500;
}
.oder-detail .bhd-total .total-div:last-child {
  margin-bottom: 0px;
}

.oder-detail .delivery-status {
  display: flex;
  align-items: baseline;
}

.oder-detail .ds-box {
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.oder-detail .ds-box-img {
  width: 70px;
  height: 70px;
  margin-inline: auto;
  background: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.oder-detail .ds-box-img img {
  height: 24px;
  width: auto;
}
.oder-detail .ds-heading {
  font-size: 13px;
  margin-bottom: 3px;
  font-weight: 500;
}
.oder-detail .ds-date {
  height: 18px;
  font-weight: 400;
  font-size: 14px;
  color: #a2a2a2;
}

.oder-detail .connector {
  width: 22%;
  height: 6px;
  background: #e9e6e6;
  position: relative;
  top: -8px;
}
.oder-detail .acive-connector {
  background: #e9e6e6;
}
.bhd-sub-heading {
  margin: 20px 0;
  display: block;
}
.oder-detail.profile_page .account .detail {
  display: flex;
  width: 50%;
}

.oder-detail.profile_page .account .detail label {
  width: 200px;
}

.oder-detail.profile_page .account .detail span {
  color: #7b7b7b;
  font-size: 15px;
}
.oder-detail.profile_page .account {
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
}

.oder-detail .log-box {
  margin-top: 20px;
}
.oder-detail .log-box strong {
  margin-bottom: 15px;
  display: block;
  font-size: 17px;
}
.oder-detail .log-box .log-line {
  font-size: 13px;
  margin-bottom: 15px;
  font-weight: 500;
  color: #555252;
  font-family: inherit;
}
.oder-detail .log-box .log-line span {
  margin-left: 20px;
}
.oder-detail .bhd-sub-heading {
  padding-top: 20px;
  display: inline-block;
}

.oder-detail .address {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 25px;
  padding-bottom: 25px;
  gap: 50px;
}
.oder-detail .address .shipp span {
  color: #7b7b7b;
  font-size: 14px;
  display: inline-block;
  padding-top: 10px;
}
.oder-detail table.seller-price-table.bhd-table {
  width: 100%;
}
.oder-detail .table-data {
  width: 130px;
}
.oder-detail table.seller-price-table {
  padding-top: 5px;
  margin-bottom: 30px;
}
.oder-detail .bhd-table .table-head:first-child,
.bhd-table .table-data:first-child {
  text-align: left !important;
}
.oder-detail .bhd-table th {
  font-weight: 500;
}
.oder-detail .bhd-table .tablerow-data {
  font-size: 13px;
  text-align: center;
}
.oder-detail .bhd-total {
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  padding: 10px 50px;
}
.oder-detail .bhd-total .total-div {
  display: flex;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
  padding-right: 86px;
  width: 39%;
  justify-content: space-between;
}
.oder-detail .bhd-total .total-div-left {
  width: 200px;
}
.oder-detail .docum {
  border-bottom: 1px solid #d9d9d9;
  border-radius: unset;
  padding: 30px 0;
}
.oder-detail .form-control label {
  color: #000;
  font-size: 17px;
  font-weight: 600;
  display: inherit;
}
.oder-detail .form-control {
  position: relative;
}
.oder-detail .form-control input {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.oder-detail .form-control svg.icon {
  position: absolute;
  right: 0%;
  top: 41%;
  background: #042d5f;
  border-radius: 0px 8px 8px 0px;
  color: #fff;
  font-size: 20px;
  padding: 11px;
  height: 50px;
  width: 50px;
}
.oder-detail a.add {
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #006ff2;
  gap: 10px;
}
