.ActInact{color: red;
    margin-left: 9px;}
    .Adhesives{    margin-left: 33px;}
.ActInactStatus{color: red;
    margin-left: 22px;}
@media (max-width:767px){
    .ActInact{color: red;
        margin-left: 0px;}
        .Adhesives{    margin-left: 0px;}
    .ActInactStatus{color: red;
        margin-left: 0px;}
}
.productTBlDt{font-size: 13px;}
.fWidScl .accordion-body{font-size: 14px; padding: 30px 15px !important;}
.NewPopU{height: 100px;
    width: 200px;
    background: red;
    right: 0%;
    z-index: 1;
    top: -48px; display: none;}
.allOrderTwobn:hover .NewPopU{display: block;}
.fWidScl .accordion-item {
    box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;
    margin-bottom: 10px;
    border-radius: 10px !important;
    
    margin-top: 18px;
}
.fWidScl .accordion-button:not(.collapsed){box-shadow: none;}
.fWidScl .accordion-button{ border-radius: 10px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.searchproductCatePop{width: 97%;
    height: auto;
    z-index: 99;}
.productTBlDtShowBox .productTBlDt98{background: #fff;     margin-left: 15px;}
/* .productTBlDtShowBox{background-color: #fff;} */
.searchproductCatePop{display: none;}