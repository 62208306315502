.mobileNav .navbar {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
}
.nav-menu-items{overflow-x: scroll;}
.mbmnuvr{margin-left: 30px;}
.mobileNav .navbar a {
    line-height: 18px;
    text-decoration: unset;
}

.mobileNav .navbar .log-icon {
    display: flex;
    gap: 25px;
}

.mobileNav .navbar h3.logo-txt {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.mobileNav .navbar h3.logo-txt span {
    color: #006FF2;
}

.mobileNav .navbar .useraction {
    display: flex;
    align-items: center;
    gap: 10px;
}

.mobileNav .navbar .useraction svg.noti {
    color: #006FF2 !important;
    font-size: 25px;
}

.mobileNav .navbar .useraction svg.user {
    font-size: 30px;
}

.mobileNav .menu-bars {
    font-size: 20px;
    background: none;
}

.mobileNav li.navbar-logo a svg{
    color: #006FF2 !important;
    font-size: 17px;
}

.mobileNav .nav-menu {
    background-color: #fff;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 9;
}

.mobileNav .nav-menu.active {
    left: 0;
    transition: 350ms;
}

.mobileNav .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 16px;
    list-style: none;
    height: 50px;
}

.mobileNav .nav-text a {
    text-decoration: none;
    color: #000;
    font-size: 15px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 8px;
}

.mobileNav .nav-text a svg{
    color: #006FF2 !important;
    font-size: 18px;
}

.mobileNav .nav-text a:hover {
    background-color: #006FF2;
    color: #fff;
}

.mobileNav .nav-text a:hover svg {
    color: #fff !important;
}

.mobileNav .nav-menu-items {
    width: 100%;
    padding-left: 0%;
    background-color: #F7F9FD;
}

.mobileNav .navbar-toggle {
    background-color: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mobileNav ul li a span {
    margin-left: 16px;
}

.mobileNav .vinn {
    padding: 40px 0px 0px 30px;
}

.mobileNav .userProfile {
    background-color: #fff;
    border-radius: 10px;
    height: auto;
    width: 250px;
    position: absolute;
    right: 14px;
    top: 50px;
    z-index: 1;
    box-sizing: border-box;
    padding: 10px 20px;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    display: none;
}

.mobileNav .userProfile ul {
    padding: 0px;
}

.mobileNav .userProfile ul li {
    list-style: none;
    padding: 10px;
}

.mobileNav .userProfile ul li:hover {
    background-color: #006FF2;
    color: #fff;
    border-radius: 7px;
}

.mobileNav li.navbar-logo a img {
    width: 150px;
}

.mobileNav li.navbar-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.mobileNav h3.account {
    padding: 5px 0px 5px 16px;
    font-size: 20px;
    padding-top: 30px;
    font-weight: 600;
}
.borders {
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: 10px 0 20px;
}